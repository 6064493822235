import styles from "./PendingEmail.module.scss";
import { doLogout, getAuthToken } from "../../../../../util/auth";
import { BASE_URL } from "../../../../../Constants";
import { json, useNavigate } from "react-router-dom";
import { clearPendingEmail } from "../../../../../store/reducers/pendingEmailSlice";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../../store/reducers/authSlice";
import { doNavigate } from "../../../../../util/auth";
import { textFor } from "../../../../../util/languages";
const PendingEmail = () => {
  const dispatch = useDispatch();
  const pendingEmail = useSelector((state) => state.pendingEmail.pendingEmail); // Select the pendingEmail state
  const navigate = useNavigate();
  console.log("In PendingVerificationemail mail is=", pendingEmail);
  const onDelete = (e) => {
    if (pendingEmail !== "") {
      const confirmed = window.confirm(textFor("areYouSure"));
      if (confirmed) {
        removePendigEmailFromUserProfile();
        dispatch(clearPendingEmail());
      }
    }
  };

  const removePendigEmailFromUserProfile = async () => {
    try {
      const token = getAuthToken();
      const phoneOrEmail = "email";
      let url =
        BASE_URL +
        "/normalUser/newLoginMethod?phoneOrEmail=" +
        encodeURIComponent(phoneOrEmail);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      console.log("response from delete newLoginMethod = ", response);

      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            { message: "Could not delete newLoginMethod." },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("successfully deleted newLogin method");
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error("Error during profile fetch:", error);
    }
  };

  const verifyPendingEmail = async () => {
    try {
      const token = getAuthToken();
      let url = BASE_URL + "/normalUser/verifyUpdatedEmail";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({
          email: pendingEmail,
          otp: "123456",
        }),
      });
      console.log("response from verify pendingEmail = ", response);

      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            { message: "Could not verify pendingEmail." },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("successfully verified pendingEmail.");
        window.alert("Your email is now changed. Please login again....");
        doNavigate(navigate, "/logout");
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error("Error during profile fetch:", error);
    }
  };

  return (
    <>
      <div>
        <div className={styles.pendingHeading}>Pending new email:</div>
        <p className={styles.newEmailField}>{pendingEmail}</p>
        <div>
          <button onClick={verifyPendingEmail}>verify</button>
          <button onClick={onDelete}>delete</button>
        </div>
      </div>
      <br />
      <hr />
    </>
  );
};
export default PendingEmail;
