import React from "react";
import styles from "./ImageButton.module.scss";

function ImageButton({
  imageUrl,
  onClick,
  onImageLoad = (e) => {},
  buttonName,
  id = "dummy",
}) {
  let customClass = "styles." + buttonName + "BtnBkColor";
  //the customClass is actually not working now because image is occupying the available space.
  return (
    <div
      id={id}
      className={`${styles.imageBtn} ${customClass}`}
      onClick={onClick}
    >
      <img
        onLoad={(e) => {
          onImageLoad("1");
        }}
        src={imageUrl}
        alt="Button"
        className={styles.image}
      />
    </div>
  );
}

export default ImageButton;
