import { LANGUAGE_KEY } from "../Constants";
let map = new Map();
export async function initLanguageMap() {
  //map starts here

  map.set("BALANA", {
    eng: "Balana",
    hindi: "बलाना"
  });
  map.set("BARADIYA", {
    eng: "Baradiya",
    hindi: "बराड़िया"
  });
  map.set("BHANKAR", {
    eng: "Bhankar",
    hindi: "भांकर"
  });
  map.set("BROTHER", {
    eng: "BROTHER",
    hindi: "भाई"
  });
  map.set("BROTHER_IN_LAW", {
    eng: "BROTHER_IN_LAW",
    hindi: "जीजाजी/सालाजी"
  });

  map.set("CLASS_05", {
    eng: "5th class",
    hindi: "पांचवी कक्षा"
  });
  map.set("CLASS_06_09", {
    eng: "6th - 9th class",
    hindi: "छठी - नौवी कक्षा"
  });
  map.set("CLASS_10", {
    eng: "10th class",
    hindi: "दसवीं कक्षा"
  });
  map.set("CLASS_12", {
    eng: "12th class",
    hindi: "बारहवीं कक्षा"
  });
  map.set("CLASS_15_BACHELORS_DEGREE", {
    eng: "Bachelor's Degree",
    hindi: "स्नातक की डिग्री"
  });
  map.set("CLASS_17_MASTERS_DEGREE", {
    eng: "Master's Degree",
    hindi: "स्नातकोत्तर डिग्री"
  });
  map.set("CLASS_20_HIGHER_DEGREE", {
    eng: "Higher Degree",
    hindi: "उच्चतर डिग्री"
  });
  map.set("COUSIN_BROTHER", {
    eng: "COUSIN_BROTHER",
    hindi: "चचेरा/ममेरा भाई"
  });
  map.set("COUSIN_SISTER", {
    eng: "COUSIN_SISTER",
    hindi: "चचेरी/ममेरी बहन"
  });
  map.set("Chandra_Dosha", {
    eng: "Chandra Dosha",
    hindi: "चंद्र दोष"
  });
  map.set("DAUGHTER", {
    eng: "DAUGHTER",
    hindi: "बेटी"
  });
  map.set("DAUGHTER_IN_LAW", {
    eng: "DAUGHTER_IN_LAW",
    hindi: "बहू"
  });
  map.set("DHABAI", {
    eng: "Dhabai",
    hindi: "धबाई"
  });
  map.set("DUBLIYA", {
    eng: "Dubliya",
    hindi: "दुबलिया"
  });
  map.set("FATHER", {
    eng: "FATHER",
    hindi: "पिताजी"
  });
  map.set("FATHER_IN_LAW", {
    eng: "FATHER_IN_LAW",
    hindi: "ससुरजी"
  });
  map.set("FEMALE", {
    eng: "FEMALE",
    hindi: "महिला"
  });
  map.set("FRIEND", {
    eng: "FRIEND",
    hindi: "दोस्त"
  });
  map.set("GAYRIYA", {
    eng: "Gayriya",
    hindi: "गारिया"
  });
  map.set("GHYAM", {
    eng: "Ghyam",
    hindi: "घ्याम"
  });
  map.set("GRAND_DAUGHTER", {
    eng: "GRAND_DAUGHTER",
    hindi: "पोती"
  });
  map.set("GRAND_FATHER", {
    eng: "GRAND_FATHER",
    hindi: "दादाजी"
  });
  map.set("GRAND_MOTHER", {
    eng: "GRAND_MOTHER",
    hindi: "दादीजी"
  });
  map.set("GRAND_SON", {
    eng: "GRAND_SON",
    hindi: "पोता"
  });
  map.set("Guru_Chandal_Dosha", {
    eng: "Guru Chandal Dosha",
    hindi: "गुरु चांडाल दोष"
  });
  map.set("HUSBAND", {
    eng: "HUSBAND",
    hindi: "पति"
  });
  map.set("JAADAM", {
    eng: "Jaadam",
    hindi: "जादम"
  });
  map.set("JHAD", {
    eng: "Jhad",
    hindi: "झड़"
  });
  map.set("KHOCHAR", {
    eng: "Khochar",
    hindi: "खोचर"
  });
  map.set("KODIYA", {
    eng: "Kodiya",
    hindi: "कोदिया"
  });
  map.set("Kaal_Sarpa_Dosha", {
    eng: "Kaal-Sarpa Dosha",
    hindi: "काल सर्प दोष"
  });
  map.set("Kemadruma_Dosha", {
    eng: "Kemadruma Dosha",
    hindi: "केमद्रुम दोष"
  });

  map.set("LILIWAL", {
    eng: "Liliwal",
    hindi: "लीलीवाल"
  });
  map.set("LUKDA", {
    eng: "Lukda",
    hindi: "लुकड़ा"
  });

  map.set("MALE", {
    eng: "MALE",
    hindi: "पुरुष"
  });
  map.set("MARRIED", {
    eng: "Married",
    hindi: "विवाहित"
  });
  map.set("MATERNAL_AUNT", {
    eng: "MATERNAL_AUNT",
    hindi: "मामीजी"
  });
  map.set("MATERNAL_GRAND_DAUGHTER", {
    eng: "MATERNAL_GRAND_DAUGHTER",
    hindi: "नातिनी"
  });
  map.set("MATERNAL_GRAND_FATHER", {
    eng: "MATERNAL_GRAND_FATHER",
    hindi: "नानाजी"
  });
  map.set("MATERNAL_GRAND_MOTHER", {
    eng: "MATERNAL_GRAND_MOTHER",
    hindi: "नानीजी"
  });
  map.set("MATERNAL_GRAND_SON", {
    eng: "MATERNAL_GRAND_SON",
    hindi: "नाती"
  });
  map.set("MATERNAL_UNCLE", {
    eng: "MATERNAL_UNCLE",
    hindi: "मामाजी"
  });
  map.set("MOTHER", {
    eng: "MOTHER",
    hindi: "माताजी"
  });
  map.set("MOTHER_IN_LAW", {
    eng: "MOTHER_IN_LAW",
    hindi: "सासूजी"
  });
  map.set("Mangal_Dosha", {
    eng: "Mangal Dosha",
    hindi: "मंगल दोष"
  });

  map.set("Max allowed members in a list are: 50", {
    eng: "Max allowed members in a list are: 50.",
    hindi: "लिस्ट में 50 से अधिक मेंबर्स सेव नहीं हो सकते."
  });
  map.set("Max lists allowed are: 10", {
    eng: "Max lists allowed are: 10",
    hindi: "10 से ज्यादा लिस्ट नहीं बनायी जा सकती."
  });
  map.set("NEPHEW_BROTHER_SIDE", {
    eng: "NEPHEW_BROTHER_SIDE",
    hindi: "भांजा"
  });
  map.set("NEPHEW_SISTER_SIDE", {
    eng: "NEPHEW_SISTER_SIDE",
    hindi: "भतीजा"
  });

  map.set("NEVER_MARRIED", {
    eng: "Never Married",
    hindi: "अविवाहित"
  });
  map.set("NIECE_BROTHER_SIDE", {
    eng: "NIECE_BROTHER_SIDE",
    hindi: "भाभीजी"
  });
  map.set("NIECE_SISTER_SIDE", {
    eng: "NIECE_SISTER_SIDE",
    hindi: "भतीजी"
  });
  map.set("NO", {
    eng: "NO",
    hindi: "नहीं"
  });
  map.set("Nadi_Dosha", {
    eng: "Nadi Dosha",
    hindi: "नाड़ी दोष"
  });

  map.set("OUDH", {
    eng: "Oudh",
    hindi: "औड़"
  });
  map.set("PATERNAL_AUNT", {
    eng: "PATERNAL_AUNT",
    hindi: "चाचीजी"
  });
  map.set("PATERNAL_UNCLE", {
    eng: "PATERNAL_UNCLE",
    hindi: "चाचाजी"
  });
  map.set("PIPADA", {
    eng: "Pipada",
    hindi: "पिपाड़ा"
  });
  map.set("Pitra_Dosha", {
    eng: "Pitra Dosha",
    hindi: "पितृ दोष"
  });
  map.set("Rahu_Dosha", {
    eng: "Rahu Dosha",
    hindi: "राहु दोष"
  });
  map.set("SEPARATED_DIVORCED", {
    eng: "Separated / Divorced",
    hindi: "अलग रह रहे / तलाकशुदा"
  });
  map.set("SISTER", {
    eng: "SISTER",
    hindi: "बहन"
  });

  map.set("SON", {
    eng: "SON",
    hindi: "बेटा"
  });
  map.set("SON_IN_LAW", {
    eng: "SON_IN_LAW",
    hindi: "दामादजी"
  });
  map.set("Vyatipata_Dosha", {
    eng: "Vyatipata Dosha",
    hindi: "व्यतिपात दोष"
  });
  map.set("WAAGAL", {
    eng: "Waagal",
    hindi: "वागल"
  });
  map.set("WARR", {
    eng: "Warr",
    hindi: "वर"
  });
  map.set("WIDOW_WIDOWER", {
    eng: "Widow / Widower",
    hindi: "विधवा / विधुर"
  });
  map.set("WIFE", {
    eng: "WIFE",
    hindi: "पत्नी"
  });
  map.set("YES", {
    eng: "YES",
    hindi: "हाँ"
  });

  map.set("accept", {
    eng: "ACCEPT",
    hindi: "स्वीकार करें"
  });

  map.set("addBalance", {
    eng: "Add Balance",
    hindi: "ऐड बैलेंस"
  });

  map.set("addFriend", {
    eng: "Add Friend",
    hindi: "फ्रेंड बनायें"
  });
  map.set("addMember", {
    eng: "Add Member",
    hindi: "ऐड मेंबर"
  });
  map.set("addToList", {
    eng: "Add to List",
    hindi: "लिस्ट में जोड़ें"
  });

  map.set("addressIsMissing", {
    eng: "Please select address from profile section.",
    hindi: "कृपया प्रोफाइल ऑप्शन में जाकर एड्रेस सेव करें."
  });

  map.set("areYouSure", {
    eng: "Are you sure ?",
    hindi: "पक्का आप ये एक्शन करना चाहते हैं ?"
  });
  map.set("areYouSureToDeleteList", {
    eng: "Are you sure to delete list?",
    hindi: "पक्का आप लिस्ट को डिलीट करना चाहते हैं?"
  });
  map.set("areYouSureToDeleteMemberFromList", {
    eng: "Are you sure to delete person from list?",
    hindi: "पक्का आप व्यक्ति को लिस्ट से डिलीट करना चाहते हैं?"
  });
  map.set("areYouSureToRenameList", {
    eng: "Are you sure to rename list?",
    hindi: "पक्का आप लिस्ट का नाम बदलना चाहते हैं?"
  });
  map.set("atLeast2LettersRequiredForListName", {
    eng: "Invalid Name: Type at least 2 letters.",
    hindi: "गलत नाम - मिनिमम 2 अक्षर टाइप करें"
  });

  map.set("badCredentials", {
    eng: "Invalid phone/email/password. Please retry by correcting details.",
    hindi:
      "लॉगिन के लिए उपयोग किये गए फ़ोन/ईमेल/पासवर्ड गलत है, कृपया सही जानकारी डालकर फिर से प्रयास करें."
  });

  map.set("balanceIsValidTill", {
    eng: "This balance is valid till: ",
    hindi: "बैलेंस वैलिडिटी की तारीख़ है:"
  });

  map.set("biggerView", {
    eng: "Bigger view:",
    hindi: "बड़ा फ़ोटो:"
  });

  map.set("block", {
    eng: "BLOCK",
    hindi: "ब्लॉक करें"
  });
  map.set("cancel", {
    eng: "CANCEL",
    hindi: "CANCEL"
  });

  map.set("cantSaveLoginAgain", {
    eng: "Your login has expired, so details can't be saved. Please login again and retry...",
    hindi:
      "आपका लॉगिन निरस्त हो चुका है, इसलिए जानकारी सेव नहीं हो पायी, कृपया फिर से लॉगिन करने के बाद ट्राय करें."
  });

  map.set("choose", {
    eng: "Choose",
    hindi: "सिलेक्ट करें"
  });

  map.set("chooseCriteriaAgainAndRetry", {
    eng: "Please choose the criteria again and retry search.",
    hindi: "कृपया बैक बटन से पीछे जाकर फिर से सर्च करें."
  });

  map.set("chooseGotra", {
    eng: "Choose Gotra",
    hindi: "गोत्र सिलेक्ट करें"
  });

  map.set("chooseKundliDosha", {
    eng: "Choose Kundli Dosha",
    hindi: "कुण्डली दोष सिलेक्ट करें"
  });

  map.set("chooseMaritalStatus", {
    eng: "Choose Marital Status",
    hindi: "वैवाहिक स्थिति सिलेक्ट करें"
  });

  map.set("cityIsRequired", {
    eng: "City is required:",
    hindi: "शहर का नाम ज़रूरी है:"
  });
  map.set("comingSoonMoreSettings", {
    eng: "Coming soon, options to change colors and text size...",
    hindi:
      "बहुत जल्दी अक्षरों की साइज एवं कलर को चेंज करने के ऑप्शंस भी यहाँ पर होंगे..."
  });

  map.set("createNewList", {
    eng: "+ Create New List",
    hindi: "+ नयी लिस्ट बनायें"
  });

  map.set("dashboard", {
    eng: "Dashboard",
    hindi: "डेशबोर्ड"
  });

  map.set("delete", {
    eng: "DELETE",
    hindi: "डिलीट करें"
  });

  map.set("detailsSavedSuccessfully", {
    eng: "Details saved successfully",
    hindi: "आपकी जानकारी सेव हो गयी है."
  });

  map.set("dummy", {
    eng: "dummy",
    hindi: "dummy"
  });
  map.set("editList", {
    eng: "Edit List",
    hindi: "एडिट करें"
  });

  map.set("emailUpdatedSuccessfully", {
    eng: "Your email ID has updated successfully.",
    hindi: "आपका ईमेल आई.डी. अपडेट हो गया है."
  });

  map.set("emptyFilters", {
    eng: "Empty Filters...!",
    hindi: "फ़िल्टर खाली है..."
  });

  map.set("family", {
    eng: "Family",
    hindi: "फ़ैमिली"
  });

  map.set("familyMember", {
    eng: "Family Member",
    hindi: "फ़ैमिली मेंबर"
  });

  map.set("familyMembers", {
    eng: "Family Members",
    hindi: "फ़ैमिली मेंबर्स"
  });

  map.set("friends", {
    eng: "Friends",
    hindi: "फ्रेंड्स"
  });

  map.set("friendsAndRequests", {
    eng: "Friends & Requests:",
    hindi: "फ्रेंड्स & रिक्वेस्ट्स:"
  });
  map.set("help", {
    eng: "Help",
    hindi: "हैल्प"
  });

  map.set("invalidCity", {
    eng: "Invalid City.",
    hindi: "शहर का नाम गलत है."
  });

  map.set("isPureVeg", {
    eng: "Pure Veg?",
    hindi: "पूर्ण शाकाहारी?"
  });

  map.set("kundliDosha:", {
    eng: "Kundli Dosha :",
    hindi: "कुण्डली दोष :"
  });
  map.set("listCreatedSuccessfully", {
    eng: "List is created successfully.",
    hindi: "लिस्ट बना दी गयी हैं."
  });
  map.set("listRenamedSuccessfully", {
    eng: "List is renamed successfully.",
    hindi: "लिस्ट का नाम बदल दिया गया है."
  });
  map.set("listSuccessfullyDeleted", {
    eng: "List is successfully deleted.",
    hindi: "लिस्ट डिलीट कर दी गयी है."
  });
  map.set("loadMore", {
    eng: "Load More",
    hindi: "और देखें"
  });

  map.set("loginButton", {
    eng: "Login",
    hindi: "लॉगिन करें"
  });
  map.set("logout", {
    eng: "Logout",
    hindi: "लॉगआउट"
  });

  map.set("memberDeletedSuccessfully", {
    eng: "Member deleted successfully.",
    hindi: "मेंबर की डिटेल्स डिलीट कर दी गयी है."
  });

  map.set("membersAddedToListSuccessfully", {
    eng: "Selected persons are added to list successfully.",
    hindi: "चुने गये लोगों को लिस्ट में जोड़ दिया गया है."
  });

  map.set("nameIsMandatoryField", {
    eng: "Name is mandatory.",
    hindi: "नाम टाइप करना ज़रूरी है."
  });
  map.set("newAndOldListNameMustBeDifferent", {
    eng: "Invalid Name: New and old names must be different.",
    hindi: "गलत नाम - नया और पुराना नाम अलग-अलग होना चाहिए."
  });
  map.set("newListName", {
    eng: "New List Name",
    hindi: "नयी लिस्ट का नाम"
  });

  map.set("newSearch", {
    eng: "New Search",
    hindi: "नयी सर्च"
  });
  map.set("news", {
    eng: "News",
    hindi: "न्यूज़"
  });

  map.set("noFamilyMemberFound", {
    eng: "No record found for any family member.",
    hindi: "आपके फॅमिली मेंबर की कोई भी जानकारी नहीं मिली."
  });

  map.set("noFieldIsUpdated", {
    eng: "No field is changed.",
    hindi: "कोई भी जानकारी अपडेट नहीं की गयी है."
  });

  map.set("noFilterSelected", {
    eng: "No filter selected !",
    hindi: "कोई फ़िल्टर नहीं !"
  });

  map.set("noKundliDosh", {
    eng: "No Dosha.",
    hindi: "कोई दोष नहीं."
  });

  map.set("noPersonFoundInList", {
    eng: "No person found in this list.",
    hindi: "इस लिस्ट में किसी व्यक्ति को सेव नहीं किया गया है"
  });

  map.set("noRecords", {
    eng: "No records...",
    hindi: "कोई रिकॉर्ड नहीं मिला..."
  });

  map.set("noSavedList", {
    eng: "No saved list:",
    hindi: "कोई लिस्ट नहीं मिली:"
  });

  map.set("ok", {
    eng: "OK",
    hindi: "OK"
  });

  map.set("otherWillSeeYourDetails", {
    eng: "When other users will search for groom or bride, then your profile and member details will be shown to them according to the specified search filters.",
    hindi:
      "जब दूसरे यूजर वर या वधू सर्च करेंगे तब उनके द्वारा चुने गए सर्च फ़िल्टर के अनुसार आपकी प्रोफ़ाइल एवं मेंबर्स की जानकारी उन्हें दिखायी जायेगी."
  });

  map.set("passwordUpdatedSucessfully", {
    eng: "Your password has updated successfully.",
    hindi: "आपका पासवर्ड अपडेट हो गया है."
  });

  map.set("perResultChargesMessage", {
    eng: "Charges = Rs 10 for each result.",
    hindi: "चार्ज : 10 रु प्रति सर्च रिजल्ट."
  });

  map.set("personDetails", {
    eng: "Person Details:",
    hindi: "व्यक्ति की जानकारी:"
  });
  map.set("personIsDeletedFromList", {
    eng: "Person is deleted from list.",
    hindi: "व्यक्ति को लिस्ट से डिलीट कर दिया गया है"
  });

  map.set("phoneUpdatedSuccessfully", {
    eng: "Your phone number has updated successfully.",
    hindi: "आपका फ़ोन नंबर अपडेट हो गया है."
  });

  map.set("pleaseAddAtLeastOneFilter", {
    eng: "Please add at lease one filter.",
    hindi: "कृपया एक फ़िल्टर ज़रूर चुने."
  });

  map.set("pleaseChooseAList", {
    eng: "Please choose a list.",
    hindi: "कृपया कोई लिस्ट सिलेक्ट करें."
  });

  map.set("pleaseChooseDistance", {
    eng: "Please choose Distance Range.",
    hindi: "कृपया फ़िल्टर में दूरी सिलेक्ट करें"
  });

  map.set("pleaseChooseGender", {
    eng: "Please choose gender.",
    hindi: "कृपया फ़िल्टर में लिंग सिलेक्ट करें"
  });

  map.set("pleaseChooseGotra", {
    eng: "Please choose gotra.",
    hindi: "कृपया फ़िल्टर में गोत्र सिलेक्ट करें"
  });

  map.set("pleaseChooseKundliDosha", {
    eng: "Please choose kundli dosha.",
    hindi: "कृपया फ़िल्टर में कुण्डली दोष सिलेक्ट करें"
  });

  map.set("pleaseChooseMaritalStatus", {
    eng: "Please choose marital status.",
    hindi: "कृपया फ़िल्टर में वैवाहिक स्थिति सिलेक्ट करें"
  });

  map.set("pleaseChooseMaxAge", {
    eng: "Please choose maximum age.",
    hindi: "कृपया फ़िल्टर में मैक्सिमम उम्र सिलेक्ट करें"
  });

  map.set("pleaseChooseMaxHeightFeet", {
    eng: "Please choose maximum height in feet.",
    hindi: "कृपया फ़ीट में मैक्सिमम हाईट सिलेक्ट करें"
  });

  map.set("pleaseChooseMaxHeightInches", {
    eng: "Please choose maximum height in inches.",
    hindi: "कृपया इंच में मैक्सिमम हाईट सिलेक्ट करें"
  });

  map.set("pleaseChooseMaxWeight", {
    eng: "Please choose maximum weight.",
    hindi: "कृपया इंच में मैक्सिमम वज़न सिलेक्ट करें"
  });

  map.set("pleaseChooseMinAge", {
    eng: "Please choose minimum age.",
    hindi: "कृपया फ़िल्टर में मिनिमम उम्र सिलेक्ट करें"
  });

  map.set("pleaseChooseMinEducation", {
    eng: "Please choose minimum education.",
    hindi: "कृपया फ़िल्टर में एजुकेशन सिलेक्ट करें"
  });

  map.set("pleaseChooseMinHeightFeet", {
    eng: "Please choose minimum height in feet.",
    hindi: "कृपया फ़ीट में मिनिमम हाईट सिलेक्ट करें"
  });

  map.set("pleaseChooseMinHeightInches", {
    eng: "Please choose minimum height in inches.",
    hindi: "कृपया इंच में मिनिमम हाईट सिलेक्ट करें"
  });

  map.set("pleaseChooseMinWeight", {
    eng: "Please choose minimum weight.",
    hindi: "कृपया मिनिमम वज़न सिलेक्ट करें"
  });

  map.set("pleaseChoosePureVeg", {
    eng: "Please choose pure veg option.",
    hindi: "कृपया पूर्ण शाकाहारी संबंधित ऑप्शन सिलेक्ट करें"
  });

  map.set("pleaseChooseSpecialAbility", {
    eng: "Please choose special ability.",
    hindi: "कृपया स्पेशल एबिलिटी सिलेक्ट करें"
  });

  map.set("pleaseChooseYearlyIncome", {
    eng: "Please choose yearly income.",
    hindi: "कृपया सालाना इनकम सिलेक्ट करें"
  });

  map.set("pleaseConfirm", {
    eng: "Please confirm",
    hindi: "कृपया कन्फर्म करें"
  });
  map.set("pleaseCreateAndChooseAList", {
    eng: "Please create a list and select that.",
    hindi: " कृपया नयी लिस्ट बना कर उसे सिलेक्ट करें."
  });

  map.set("pleaseLoginAgainToMakeSettingsEffective", {
    eng: "Your settings are changed, please login again to start using new settings.",
    hindi:
      "आपकी सेटिंग्स सेव हो गयी है, कृपया नयी सेटिंग्स का उपयोग करने के लिए फिर से लॉगिन करें."
  });

  map.set("pleaseProvideAddressCity", {
    eng: "Please provide address city from Profile section.",
    hindi:
      "कृपया यूज़र प्रोफाइल में जाकर एड्रेस में राज्य एवं शहर का नाम सेव करें."
  });

  map.set("pleaseSelectAmount", {
    eng: "Please select amount.",
    hindi: "कृपया राशि सेलेक्ट करें."
  });

  map.set("pleaseTypeAtLeast2CharForName", {
    eng: "Please type at least 2 letters for name.",
    hindi: "कृपया नाम के मिनिमम 2 अक्षर टाइप करें"
  });

  map.set("plsUseDifferentSearchFilter", {
    eng: "Please use different search filter.",
    hindi: "कृपया किसी दूसरे सर्च फ़िल्टर का उपयोग करें."
  });
  map.set("pressBackAndGoBack", {
    eng: "Press back button and go back.",
    hindi: "बैक बटन टच कर के पिछले पेज पर जाएँ."
  });

  map.set("pressOkToGetBalance", {
    eng: "Press OK to get FREE balance of Rs. ",
    hindi: "OK टच करके आपको बैलेंस में मिलेंगे Rs. "
  });
  map.set("profile", {
    eng: "Profile",
    hindi: "प्रोफ़ाइल"
  });

  map.set("promotionalOffer", {
    eng: "Promotional Offer!",
    hindi: "प्रमोशनल ऑफर!"
  });

  map.set("registerButton", {
    eng: "Register",
    hindi: "रजिस्टर करें"
  });

  map.set("reject", {
    eng: "REJECT",
    hindi: "रिजेक्ट"
  });
  map.set("rename", {
    eng: "Rename",
    hindi: "नाम बदलें"
  });

  map.set("search", {
    eng: "Search",
    hindi: "सर्च"
  });
  map.set("searchBride", {
    eng: "Search Bride",
    hindi: "वधू खोजें"
  });

  map.set("searchCriteria", {
    eng: "Search criteria:",
    hindi: "सर्च फिल्टर्स:"
  });
  map.set("searchGroom", {
    eng: "Search Groom",
    hindi: "वर खोजें"
  });

  map.set("searchResults", {
    eng: "Search results:",
    hindi: "सर्च रिज़ल्ट्स:"
  });

  map.set("selectAtLeastOnePerson", {
    eng: "Please select, at least one person, by touching yellow cirle. The selected persons will be saved in the list.",
    hindi:
      "कृपया, पीले-गोल निशान पर टच करके, कुछ लोगों को सेलेक्ट करें, उन्हें ही लिस्ट में सेव किया जाएगा."
  });
  map.set("selectKundliDoshas:", {
    eng: "Select Kundli Doshas :",
    hindi: "कुण्डली दोष सिलेक्ट करें :"
  });
  map.set("settings", {
    eng: "Settings",
    hindi: "सेटिंग्स"
  });

  map.set("sorryForThisError", {
    eng: "We are sorry for this error.",
    hindi: "रूकावट के लिए खेद है."
  });

  map.set("suggestionToSearchAndShortlistGrooms1", {
    eng: "No saved list found.",
    hindi: "आपके लिए कोई लिस्ट नहीं मिली है."
  });

  map.set("suggestionToSearchAndShortlistGrooms2", {
    eng: "You can do new search and save the shortlisted members in a list to view them later.",
    hindi:
      "आप वर/वधु सर्च करने के बाद नयी लिस्ट बनाकर, उस लिस्ट में मनपसंद लोगों को सेव कर के रख सकते हैं, ताकि आपको फिर से उन्हें सर्च नहीं करना पड़ेगा."
  });

  map.set("suggestionToSearchAndShortlistGrooms3", {
    eng: "Click on New Search button to do the search.",
    hindi: "कृपया सर्च करने के लिए ऊपर दी गयी नयी सर्च बटन का उपयोग करें."
  });
  map.set("thisListIsDeleted", {
    eng: "This list is deleted.",
    hindi: "यह लिस्ट डिलीट हो चुकी है."
  });

  map.set("topUpWalletFromProfile", {
    eng: "Your wallet balance is low, please add balance from Profile section.",
    hindi: "आपका वॉलेट बैलेंस कम है, कृपया प्रोफाइल में जाकर रिचार्ज करें."
  });

  map.set("touchBelowButtonToGoBack", {
    eng: "Please click below button to go back.",
    hindi: "कृपया नीचे दिए गए बटन को टच करें."
  });

  map.set("unblock", {
    eng: "UNBLOCK",
    hindi: "अनब्लॉक करें"
  });

  map.set("unknown_error", {
    eng: "We are sorry for this error, please refresh and retry.",
    hindi: "रूकावट के लिए खेद है, कृपया पेज को रिफ्रेश कर पुनः प्रयास करें."
  });

  map.set("useAddButtonForFriends", {
    eng: "Use Add button to send new friend requests.",
    hindi: "नयी फ्रेंड रिक्वेस्ट सेंड करने के लिए ऐड बटन का यूज़ करें."
  });

  map.set("useAddMemberButton", {
    eng: "Please use add member button from top right side to save details about your family members.",
    hindi:
      "कृपया ऊपर दिए गए ऐड मेंबर बटन पर टच कर के अपने फ़ैमिली मेंबर्स की जानकारी सेव कीजिये."
  });
  map.set("watchHelpVideosForMoreInfo", {
    eng: "For more details, please Watch videos from Help section.",
    hindi: "अधिक जानकारी के लिये हैल्प सेक्शन में जाकर वीडियो देखें."
  });

  map.set("welcomeTitleLine1", {
    eng: "Welcome to this site",
    hindi: "यादव अहीर समाज की इस वेब साईट पर"
  });

  map.set("welcomeTitleLine2", {
    eng: "For Yadav Ahir Community",
    hindi: "आपका हार्दिक स्वागत है"
  });
  map.set("zzz", {
    eng: "zzz",
    hindi: "zzz"
  });
  //map ends here

  console.log(
    "language map init done.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
  );
}

export function textFor(key) {
  let langObj = {
    eng: ".",
    hindi: ".",
  };
  if (map.keys().toArray().length < 1) {
    initLanguageMap();
  }
  if (map.has(key)) {
    langObj = map.get(key);
  }
  const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
  if (currentLanguage) {
    switch (currentLanguage) {
      case "HINDI":
        return langObj.hindi;
      case "ENGLISH":
        return langObj.eng;
      default:
        return langObj.eng;
    }
  }
  return langObj.eng;
}
