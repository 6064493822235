import { Outlet, useNavigate } from "react-router-dom";
import { doNavigate, getAuthToken } from "../util/auth";
import MainNavigation from "../components/MainNavigation";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/reducers/authSlice";
import { useEffect } from "react";
import styles from "./RootLayout.module.scss";

function useGetTokenAndUpdateStore() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  //even if there is no loader defined for RootLayout, but calling this is
  //somehow refreshing and it is picking data from localStorage, so the
  //mainNavigation is loading fine else it will sometime hide the mainNavigation
  //so our goal is to remove this below line.
  // const tokenFromLoader = null; //useLoaderData();
  // console.log("token on root from loader = ", tokenFromLoader);

  const tokenFromLocalStorage = getAuthToken();
  // console.log("token on root from localStorage = ", tokenFromLocalStorage);
  //giving high priority to tokenFromLocalStorage, if that is 1
  //sometime when I was manually changing url and then coming on the
  //dashboard, the loader wa not getting called, so I amd using localStorage
  //reading also here to get the token status.
  const token = tokenFromLocalStorage; //|| (tokenFromLocalStorage && tokenFromLoader);
  if (token && !isLoggedIn) {
    dispatch(authActions.loginSuccess({ token }));
  }
  return token;
}

function RootLayout() {
  const isLoggedIn = getAuthToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      doNavigate(navigate, "/welcome");
    }
  }, [isLoggedIn, navigate]);
  //although not used but removing this was causing error so kept it.
  const token = useGetTokenAndUpdateStore() && isLoggedIn;
  // console.log("rendering rootLayout, isLoggedIn:", isLoggedIn);
  return (
    <div className={styles.rootWrapper}>
      {isLoggedIn && <MainNavigation />}
      <main className={styles.mainContainer}>
        <Outlet />
      </main>
    </div>
  );
}

export default RootLayout;
