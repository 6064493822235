import commonStyles from "../../../commonStyles.module.scss";
import AddressFields from "./addressFields/AddressFields";
import { doLogout, doNavigate, getAuthToken } from "../../../util/auth";
import { Loader } from "../../UI/ErrorModal";
import { BASE_URL } from "../../../Constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/reducers/authSlice";
import { updateProfileDetails } from "../../../store/reducers/userProfileSlice";
import { useSelector } from "react-redux";
import { textFor } from "../../../util/languages";
const AddressFieldsWrapper = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, navigate]);
  const initialUsersDetails = {
    usersStateId: props.registrationDetails.addressStateName,
    usersCityId: props.registrationDetails.addressCityName,
    usersDetailedAddress: props.registrationDetails.detailedAddress,
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userDetails, setUserDetails] = useState(initialUsersDetails);

  const updateAddressStateId = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersStateId: value };
    });
  };
  const updateAddressCityId = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersCityId: value };
    });
  };

  const updateDetailedAddress = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersDetailedAddress: value };
    });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  function cityIsValid() {
    console.log(userDetails);
    if (userDetails.usersCityId !== null && userDetails.usersCityId !== "") {
      return true;
    }
    return false;
  }
  const handleOkClick = async () => {
    if (!cityIsValid()) {
      window.alert(textFor("invalidCity"));
      return;
    }
    setIsEditing(false);
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateRegistrationDetails";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          addressStateName: userDetails.usersStateId,
          addressCityName: userDetails.usersCityId,
          language: userDetails.usersLanguageName,
          detailedAddress: userDetails.usersDetailedAddress,
        }),
      });

      console.log("response is = ", response);

      if (response.ok) {
        //mark profile dirty so that it will be fetched again
        dispatch(updateProfileDetails({ isProfileDirty: "true" }));
      }

      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          alert("Invalid response: " + response);
        }
      }
    } catch (error) {
      console.error("Error during POST request:", error);
      doNavigate(navigate, "/logout");
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    setUserDetails((userDetails) => initialUsersDetails);
    setIsEditing(false);
  };
  function getOkAndCancelButtons() {
    return (
      <>
        <button
          className={commonStyles.greenButton}
          onClick={handleOkClick}
          id="addressTabOkButton"
        >
          Ok
        </button>
        <button
          className={commonStyles.magentaButton}
          onClick={handleCancelClick}
          id="addressTabCancelButton"
        >
          Cancel
        </button>
      </>
    );
  }
  function getEditButton() {
    return (
      <button
        className={commonStyles.darkRedButton}
        onClick={handleEditClick}
        id="addressTabEditButton"
      >
        Edit
      </button>
    );
  }
  return (
    <>
      {isSubmitting && <Loader />}
      <div
        className={`${commonStyles.topPanel} ${commonStyles.rightAligned} ${commonStyles.transparentBackground}`}
      >
        {isEditing ? getOkAndCancelButtons() : getEditButton()}
      </div>
      <AddressFields
        isEditing={isEditing}
        usersStateId={userDetails.usersStateId}
        usersCityId={userDetails.usersCityId}
        usersDetailedAddress={userDetails.usersDetailedAddress}
        updateAddressStateId={updateAddressStateId}
        updateAddressCityId={updateAddressCityId}
        updateDetailedAddress={updateDetailedAddress}
      />
    </>
  );
};
export default AddressFieldsWrapper;
