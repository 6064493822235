import EditableField from "./editableEmail/EditableField";
import { BASE_URL } from "../../../../Constants";
import { doLogout, getAuthToken } from "../../../../util/auth";
import { useState } from "react";
import { Loader } from "../../../UI/ErrorModal";
import { textFor } from "../../../../util/languages";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/reducers/authSlice";
import { json } from "react-router-dom";
function PasswordField(props) {
  const dispatch = useDispatch();
  console.log("props in PasswordField = ", props);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateNewPassword = (newPassword) => {
    if (newPassword !== "") {
      apiCall_setNewPassword(newPassword);
    }
  };
  const apiCall_setNewPassword = async (newPassword) => {
    setIsEditing(false);
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateRegistrationDetails";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          password: newPassword,
        }),
      });

      console.log("response is = ", response);
      if (!response.ok) {
        if (response.status === 403) {
          console.log("got 403....");
          doLogout(dispatch, authActions);
        } else {
          throw json(
            {
              message: "Could not update password.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        window.alert(textFor("passwordUpdatedSucessfully"));
      }
    } catch (error) {
      console.error("Error during POST request:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      {isSubmitting && <Loader />}
      <label>Password:</label>
      {/* Don't include the component in the label, else it will do multiple click
      and won't show the dialog to input new email */}
      <EditableField
        initialFieldValue="**********"
        updateFieldValue={updateNewPassword}
        placeholder="Type new password here"
        heading="Enter new password:"
      />
    </>
  );
}
export default PasswordField;
