import React from "react";
import ReactDOM from "react-dom";
import Card from "../Card";
import classes from "../ErrorModal.module.scss";
import { BackDrop } from "../ErrorModal";
import Button from "../Button";
export const ModalOverlay = ({
  title,
  getContent,
  onOkClick,
  okButtonText = "OK",
  cancelButtonText,
  onCancelClick,
}) => {
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h2 id="dialogTitle">{title}</h2>
      </header>
      <div className={classes.content}>{getContent()}</div>
      <footer className={classes.action}>
        <Button id="alertOkButton" onClick={onOkClick} autoFocus={true}>
          {okButtonText}
        </Button>
        {cancelButtonText ? (
          <Button
            id="alertCancelButton"
            onClick={onCancelClick}
            autoFocus={true}
          >
            {cancelButtonText}
          </Button>
        ) : (
          ""
        )}
      </footer>
    </Card>
  );
};
export function getBackdropView(onBackdropClicked) {
  return ReactDOM.createPortal(
    <BackDrop onConfirm={onBackdropClicked} />,
    document.getElementById("backdrop-root")
  );
}
function AlertDialog({
  onOkClick,
  onBackdropClicked,
  title,
  getContent,
  okButtonText = "OK",
  cancelButtonText = "",
  onCancelClick = (e) => {},
}) {
  return (
    <div>
      {getBackdropView(onBackdropClicked)}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={title}
          getContent={getContent}
          onOkClick={onOkClick}
          okButtonText={okButtonText}
          cancelButtonText={cancelButtonText}
          onCancelClick={onCancelClick}
        />,
        document.getElementById("overlay-root")
      )}
    </div>
  );
}
export default AlertDialog;
