import { act, useEffect } from "react";
import { CREATING_NEW_MEMBER, LAST_PAGE } from "../../Constants";
import MemberDetails from "../memberDetails/MemberDetails";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { doNavigate } from "../../util/auth";
import { saveActiveRoute } from "../../util/viewUtils";

function AddNewMember() {
  const props = {
    registrationDetails: {
      birthYear: "",
      educationLevelType: "",
      familyRelationType: "",
      fullName: "",
      genderType: "",
      gotraType: "",
      heightFeetType: "",
      heightInchesType: "",
      id: "",
      incomeSourceDetails: "",
      yearlyIncome: "",
      isPhysicallyChallenged: "",
      isPureVegetarian: "",
      maritalStatusType: "",
      otherDetails: "",
      ownerUserId: localStorage.getItem("userId"),
      profileImageUrl: "",
      weightRangeType: "",
      isEditing: true,
    },
  };

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
    localStorage.setItem(CREATING_NEW_MEMBER, "STARTED");
  }, [auth.isAuthenticated, navigate]);
  const activeRoute = useLocation();
  saveActiveRoute(activeRoute);

  return <MemberDetails registrationDetails={props.registrationDetails} />;
}
export default AddNewMember;
