import ImageButton from "../../components/UI/imageButton/ImageButton";
import familyIconImageUrl from "../../assets/familyIcon.png";
import styles from "./TestViewGrid.module.scss";
function TestViewGrid() {
  return (
    <div className={styles.parentDiv}>
      <div className={styles.gridContainer}>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
        <div className={styles.gridItem}>
          <ImageButton
            id="familyMembers"
            imageUrl={familyIconImageUrl}
            onClick={() => {}}
            onImageLoad={() => {}}
            buttonName="family"
          />
        </div>
      </div>
    </div>
  );
}
export default TestViewGrid;
