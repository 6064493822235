import AlertDialog from "../alertDialog/AlertDialog";
import styles from "./PersonDetailsDialog.module.scss";
import CircularImage from "../CircularImage/CircularImage";
import userPlaceholderImageUrl from "../../../assets/userPlaceholder.png";
import educationOptionsJson from "../../../jsonConstants/educationTypes.json";
import yearlyIncomeOptionsJson from "../../../jsonConstants/yearlyIncome.json";
import maritalStatusOptionsJson from "../../../jsonConstants/maritalStatusTypes.json";
import weightRangeJson from "../../../jsonConstants/weightRange.json";
import heightFeetJson from "../../../jsonConstants/heightFeet.json";
import heightInchJson from "../../../jsonConstants/heightInch.json";
import { textFor } from "../../../util/languages";

const { educationOptions } = educationOptionsJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { weightRangeOptions } = weightRangeJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
function PersonDetailsDialog({
  onClose,
  personDetails,
  getViewForTopRightArea,
}) {
  function showFieldIfAvailable(name, field) {
    if (field !== "" && field != null && field !== undefined) {
      //parse YES/NO dropdown field
      if (field === true) {
        //for field value = true for yes no dropdown flags
        field = "YES";
      } else if (field === false) {
        field = "NO";
      }

      return (
        <div>
          <b>{name}</b> {field}
        </div>
      );
    }
  }
  function getAge(birthYear) {
    if (birthYear) {
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear;
    } else {
      return "";
    }
  }

  function getHeight(feet, inches) {
    let retVal = "";
    if (feet) {
      const matchedRow = heightFeetOptions.filter((h) => h.id === feet);
      retVal = matchedRow[0].name;
      if (inches != null && inches != "" && inches.includes("INCH")) {
        const matchedRow = heightInchOptions.filter((h) => h.id === inches);
        retVal += ", " + matchedRow[0].name;
      }
    }
    return retVal;
  }
  function getWeight(weightStr) {
    let retVal = "";
    if (weightStr) {
      const matchedRow = weightRangeOptions.filter((w) => w.id === weightStr);
      retVal = matchedRow[0].name;
    }
    return retVal;
  }
  function getMaritalStatus(mStr) {
    let retVal = "";
    if (mStr) {
      const matchedRow = maritalStatusOptions.filter((m) => m.id === mStr);
      retVal = matchedRow[0].name;
    }

    return retVal;
  }
  function getYearlyIncome(yiStr) {
    let retVal = "";
    if (yiStr) {
      const matchedRow = yearlyIncomeOptions.filter((yi) => yi.id === yiStr);
      retVal = matchedRow[0].name;
    }

    return retVal;
  }
  function getEducation(eduStr) {
    let retVal = "";
    if (eduStr) {
      const matchedRow = educationOptions.filter((e) => e.id === eduStr);
      retVal = matchedRow[0].name;
    }
    return retVal;
  }

  console.log("got personDetails = ", personDetails);

  function getPersonDetailsView() {
    return (
      <div className={styles.detailsWrapper}>
        <div className={styles.topPanelWithImage}>
          <CircularImage
            profileImageUrl={
              personDetails.profileImageUrl || userPlaceholderImageUrl
            }
          />
          {getViewForTopRightArea()}
        </div>
        {showFieldIfAvailable("Name:", personDetails.fullName)}
        {showFieldIfAvailable("Gotra:", personDetails.gotra)}
        {showFieldIfAvailable("Gender:", personDetails.genderType)}
        {showFieldIfAvailable("Gotra:", personDetails.gotraType)}
        {showFieldIfAvailable("Age:", getAge(personDetails.birthYear))}
        {showFieldIfAvailable("Vegetarian:", personDetails.isPureVegetarian)}
        {showFieldIfAvailable(
          "Marital Status:",
          getMaritalStatus(personDetails.maritalStatusType)
        )}
        {showFieldIfAvailable(
          "Yearly Income:",
          getYearlyIncome(personDetails.yearlyIncome)
        )}
        {showFieldIfAvailable(
          "Income Source:",
          personDetails.incomeSourceDetails
        )}
        {showFieldIfAvailable(
          "Education:",
          getEducation(personDetails.educationLevelType)
        )}
        {showFieldIfAvailable(
          "Education Details:",
          personDetails.educationDetails
        )}
        {showFieldIfAvailable(
          "SpeciallyAbled:",
          personDetails.isSpeciallyAbled
        )}
        {showFieldIfAvailable(
          "Spl Ability Details:",
          personDetails.specialAbilityDetails
        )}
        {showFieldIfAvailable(
          "Height:",
          getHeight(
            personDetails.heightFeetType,
            personDetails.heightInchesType
          )
        )}
        {showFieldIfAvailable(
          "Weight:",
          getWeight(personDetails.weightRangeType)
        )}
        {showFieldIfAvailable("Other Details:", personDetails.otherDetails)}
        {/* {showFieldIfAvailable("Guardian:", personDetails.fullName)} */}
        {/* {showFieldIfAvailable("Relation:", personDetails.relation)} */}
      </div>
    );
  }
  return (
    <AlertDialog
      onOkClick={onClose}
      onBackdropClicked={onClose}
      title={textFor("personDetails")}
      getContent={getPersonDetailsView}
    />
  );
}
export default PersonDetailsDialog;
