import Dropdown from "../../UI/dropdown/Dropdown";
import languagesJson from "../../../jsonConstants/languages.json";
const LanguageField = (props) => {
  const { languages } = languagesJson;
  // console.log("LanguageField props=", props);
  const getLanguageNameFromId = (id) => {
    const languageObj = languages.find((language) => language.id === id);
    return languageObj.name;
  };
  const getLanguageIdFromName = (languageName) => {
    const languageObj = languages.find(
      (language) => language.name === languageName
    );
    return languageObj.id;
  };
  return (
    <Dropdown
      id="languageDropdown"
      label="Language"
      value={getLanguageIdFromName(props.usersLanguageName)}
      onChange={(e) =>
        props.updateLanguage(getLanguageNameFromId(e.target.value))
      }
      options={languages}
      disabled={!props.isEditing} //disable if not editing
    />
  );
};

export default LanguageField;
