import { doLogout, getAuthToken } from "./auth";

export async function patchWithoutBody(url, dispatch, authActions) {
  const token = getAuthToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
  });

  if (!response.ok) {
    if (response.status === 403) {
      return doLogout(dispatch, authActions);
    }
  }
  return response;
}
export function delayForMs(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
