// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";
let initialState = {
  addressCityName: "",
  addressStateName: "",
  balance: "",
  birthYear: "",
  detailedAddress: "",
  educationDetails: "",
  educationLevelType: "",
  email: "",
  familyRelationType: "",
  fullName: "",
  genderType: "",
  gotraType: "",
  heightFeetType: "",
  heightInchesType: "",
  id: "",
  incomeSourceDetails: "",
  isChandraDosh: "",
  isEmailVerified: "",
  isGuruChandalDosh: "",
  isKaalSarpaDosh: "",
  isKemadrumaDosh: "",
  isLoading: "",
  isManglikDosh: "",
  isNadiDosh: "",
  isPhoneVerified: "",
  isPitraDosh: "",
  isProfileDirty: "",
  isPureVegetarian: "",
  isRahuDosh: "",
  isSpeciallyAbled: "",
  isVyatipataDosh: "",
  language: "",
  lastPendingPhoneOtpTimestamp: "",
  maritalStatusType: "",
  otherDetails: "",
  ownerUserId: "",
  pendingEmail: "",
  pendingPhone: "",
  pendingPhoneVerifyAttempts: "",
  phone: "",
  phoneOtpSentCount: "",
  profileImageUrl: "",
  role: "",
  specialAbilityDetails: "",
  verificationStatus: "",
  weightRangeType: "",
  yearlyIncome: "",
};

const stateBeforeRefresh = loadState();
if (stateBeforeRefresh.userProfile) {
  initialState = stateBeforeRefresh.userProfile;
}
const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    fetchProfileStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchProfileSuccess(state, action) {
      console.log("In fetchProfileSuccess, action =", action);
      const {
        addressCityName,
        addressStateName,
        balance,
        birthYear,
        detailedAddress,
        educationDetails,
        educationLevelType,
        email,
        familyRelationType,
        fullName,
        genderType,
        gotraType,
        heightFeetType,
        heightInchesType,
        id,
        incomeSourceDetails,
        isChandraDosh,
        isEmailVerified,
        isGuruChandalDosh,
        isKaalSarpaDosh,
        isKemadrumaDosh,
        isManglikDosh,
        isNadiDosh,
        isPhoneVerified,
        isPitraDosh,
        isPureVegetarian,
        isRahuDosh,
        isSpeciallyAbled,
        isVyatipataDosh,
        language,
        lastPendingPhoneOtpTimestamp,
        maritalStatusType,
        otherDetails,
        ownerUserId,
        pendingEmail,
        pendingPhone,
        pendingPhoneVerifyAttempts,
        phone,
        phoneOtpSentCount,
        profileImageUrl,
        role,
        specialAbilityDetails,
        verificationStatus,
        weightRangeType,
        yearlyIncome,
      } = action.payload;

      state.addressCityName = addressCityName;
      state.addressStateName = addressStateName;
      state.balance = balance;
      state.birthYear = birthYear;
      state.detailedAddress = detailedAddress;
      state.educationDetails = educationDetails;
      state.educationLevelType = educationLevelType;
      state.email = email;
      state.familyRelationType = familyRelationType;
      state.fullName = fullName;
      state.genderType = genderType;
      state.gotraType = gotraType;
      state.heightFeetType = heightFeetType;
      state.heightInchesType = heightInchesType;
      state.id = id;
      state.incomeSourceDetails = incomeSourceDetails;
      state.isChandraDosh = isChandraDosh;
      state.isEmailVerified = isEmailVerified;
      state.isGuruChandalDosh = isGuruChandalDosh;
      state.isKaalSarpaDosh = isKaalSarpaDosh;
      state.isKemadrumaDosh = isKemadrumaDosh;
      state.isLoading = false;
      state.isManglikDosh = isManglikDosh;
      state.isNadiDosh = isNadiDosh;
      state.isPhoneVerified = isPhoneVerified;
      state.isPitraDosh = isPitraDosh;
      state.isPureVegetarian = isPureVegetarian;
      state.isRahuDosh = isRahuDosh;
      state.isSpeciallyAbled = isSpeciallyAbled;
      state.isVyatipataDosh = isVyatipataDosh;
      state.language = language;
      state.lastPendingPhoneOtpTimestamp = lastPendingPhoneOtpTimestamp;
      state.maritalStatusType = maritalStatusType;
      state.otherDetails = otherDetails;
      state.ownerUserId = ownerUserId;
      state.pendingEmail = pendingEmail;
      state.pendingPhone = pendingPhone;
      state.pendingPhoneVerifyAttempts = pendingPhoneVerifyAttempts;
      state.phoneOtpSentCount = phoneOtpSentCount;
      state.phone = phone;
      state.profileImageUrl = profileImageUrl;
      state.role = role;
      state.specialAbilityDetails = specialAbilityDetails;
      state.verificationStatus = verificationStatus;
      state.weightRangeType = weightRangeType;
      state.yearlyIncome = yearlyIncome;
    },
    fetchProfileFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearProfile(state, action) {
      console.log("in CLEARPROFILE...........");
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    updateBalance(state, action) {
      console.log("In update balance. state=", state, ",   action=", action);
      state.balance = action.payload.balance;
    },
    updateProfileDetails(state, action) {
      console.log("updateProfileDetails state=", state, ",   action=", action);
      const objOfChangedItems = action.payload;
      for (const key in objOfChangedItems) {
        if (key in initialState && state[key] !== objOfChangedItems[key]) {
          state[key] = objOfChangedItems[key];
        }
      }
    },
  },
});

export default userProfileSlice.reducer;
export const {
  clearProfile,
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileFailure,
  updateBalance,
  updateProfileDetails,
} = userProfileSlice.actions;
