import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer"; // Import your rootReducer
import rootSaga from "./saga/rootSaga"; // Import your rootSaga
import { LOGIN_STATUS } from "../Constants";

// Save Redux state slices to localStorage
const saveState = (state) => {
  if (!localStorage.getItem(LOGIN_STATUS)) {
    return;
  }
  console.log("SAVING REDUX......");
  try {
    // Serialize each slice separately and save to localStorage
    Object.keys(state).forEach((key) => {
      const serializedState = JSON.stringify(state[key]);
      // console.log("serializedState=", serializedState);
      localStorage.setItem(`reduxState_${key}`, serializedState);
    });
  } catch (err) {
    console.log("Error while saving redux to localStorage: ", err);
  }
};

// Load Redux state slices from localStorage
export const loadState = () => {
  if (!localStorage.getItem(LOGIN_STATUS)) {
    return {};
  }
  console.log("READING LOCALSTORAGE FOR REDUX........");
  try {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith("reduxState_")
    );

    const state = {};
    keys.forEach((key) => {
      const serializedState = localStorage.getItem(key);
      const sliceName = key.replace("reduxState_", "");
      if (serializedState !== null) {
        state[sliceName] = JSON.parse(serializedState);
      }
    });
    console.log("got state from localStorage = ", state);
    return state;
  } catch (err) {
    console.log("Error while reading back redux from localStorage: ", err);
    return undefined;
  }
};

// export const persistedState = loadState(); // Load state slices from localStorage if exists

// console.log("persistedState state = ", persistedState);

// Create Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure Redux store with middleware and devTools
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});
sagaMiddleware.run(rootSaga); // Run the root saga

export default store;

window.addEventListener("beforeunload", () => {
  saveState(store.getState());
});

store.subscribe(() => {
  saveState(store.getState()); // Save state slices to localStorage on every Redux state change
});
