import React, { useRef } from "react";
import { FaTimes } from "react-icons/fa";
import styles from "./Modal.module.scss";

const Modal = (props) => {
  const inputFieldRef = useRef(null);
  const onOk = (e) => {
    props.onOk(inputFieldRef.current.value);
  };
  return (
    <div className={`${styles.modal} ${styles.open}`}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>{props.modalHeading}</h2>
          <button className={styles.closeBtn} onClick={props.onCancel}>
            <FaTimes />
          </button>
        </div>
        <div className={styles.modalBody}>
          <label htmlFor="inputField">{props.inputTitle || ""}</label>
          <input
            type="text"
            id="inputField"
            ref={inputFieldRef}
            placeholder={props.placeholder || ""}
          />
        </div>
        <div className={styles.modalFooter}>
          <button onClick={onOk}>{props.okButtonText || "OK"}</button>
          <button onClick={props.onCancel}>
            {props.cancelButtonText || "CANCEL"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
