import { useState } from "react";
import _ from "lodash";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import {
  getBirthYear,
  getSpecialAbilityDetails,
  getEducationDetails,
  getEducationLevelType,
  getFamilyRelationType,
  getGenderType,
  getHeightFeetType,
  getHeightInchesType,
  getIncomeSourceDetails,
  getYearlyIncome,
  getIsEditing,
  getIsSpeciallyAbled,
  getIsPureVegetarian,
  getMaritalStatusType,
  getOtherDetails,
  getProfileImageUrl,
  getShouldShowChallengeDetails,
  getShowEduDetails,
  getWeightRangeType,
  getGotraType,
  getFullName,
  getKundliDoshas,
} from "./fieldValueUtils";

const usePropsToGetFormData = (props) => {
  const initialState = {
    birthYear: getBirthYear(props),
    educationDetails: getEducationDetails(props),
    educationLevelType: getEducationLevelType(props),
    enableCaptureImage: false,
    familyRelationType: getFamilyRelationType(props),
    fullName: getFullName(props),
    genderType: getGenderType(props),
    gotraType: getGotraType(props),
    heightFeetType: getHeightFeetType(props),
    heightInchesType: getHeightInchesType(props),
    incomeSourceDetails: getIncomeSourceDetails(props),
    isDefaultImageUsed: !(props.registrationDetails.profileImageUrl && true),
    isEditing: getIsEditing(props),
    isPureVegetarian: getIsPureVegetarian(props),
    isSpeciallyAbled: getIsSpeciallyAbled(props),
    kundliDoshas: getKundliDoshas(props),
    maritalStatusType: getMaritalStatusType(props),
    otherDetails: getOtherDetails(props),
    profileImage: getProfileImageUrl(props),
    showChallengeDetails: getShouldShowChallengeDetails(props),
    showEduDetails: getShowEduDetails(props),
    specialAbilityDetails: getSpecialAbilityDetails(props),
    weightRangeType: getWeightRangeType(props),
    yearlyIncome: getYearlyIncome(props),
  };
  const [formData, setFormData] = useState(initialState);

  function resetFormFields() {
    initialState.isEditing = false;
    //setFormData(initialState) - this is having problem
    //not sure, but when I was editing a member then pressng cancel was not working
    //so I did the destructuring and passed the destructured object
    //It's possible that since initialState object is already in state, then again calling
    //with initialState, it will just check if the object address is the same, it won't
    //check the values so we have to send new object....??
    setFormData({ ...initialState });
  }
  function clearFormFields() {
    const emptyFormData = {};
    for (const key in formData) {
      emptyFormData[key] = "";
    }
    emptyFormData.isEditing = formData.isEditing;
    emptyFormData.profileImage = userPlaceholderImageUrl;
    emptyFormData.isDefaultImageUsed = true;
    setFormData(emptyFormData);
  }

  function setIsDefaultImageUsed(value) {
    setFormData((prevState) => ({ ...prevState, isDefaultImageUsed: value }));
  }
  function setEnableCaptureImage(value) {
    // console.log("Setting enableCaptureImage value to :", value);
    setFormData((prevState) => ({ ...prevState, enableCaptureImage: value }));
  }
  function setProfileImage(value) {
    // console.log("setting profileImage To= ", value);
    setFormData((prevState) => ({ ...prevState, profileImage: value }));
  }
  function setIsEditing(value) {
    // console.log("Setting isEditing to:", value);
    setFormData((prevState) => {
      return { ...prevState, isEditing: value };
    });
    //this will not show updated value, log this in useEffect instead of here.
    // console.log("formData is now: ", formData);
  }
  function setShowEduDetails(value) {
    setFormData((prevState) => ({ ...prevState, showEduDetails: value }));
  }
  function setShowChallengeDetails(value) {
    setFormData((prevState) => ({ ...prevState, showChallengeDetails: value }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("changed ", name, ", value = ", value);
    switch (name) {
      case "educationLevelType":
        const lowerEduGroup = ["CLASS_05", "CLASS_06_09", "CLASS_10"];
        if (!_.includes(lowerEduGroup, value)) {
          //2 is 10th class
          setShowEduDetails(true);
        } else {
          setShowEduDetails(false);
        }
        setFormData((prevState) => ({
          ...prevState,
          educationLevelType: value,
        }));
        break;

      case "isSpeciallyAbled":
        if (value === "YES") {
          setShowChallengeDetails(true);
        } else {
          setShowChallengeDetails(false);
        }
        setFormData((prevState) => ({
          ...prevState,
          isSpeciallyAbled: value,
        }));
        break;

      default:
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }
  };

  return {
    formData,
    handleChange,
    resetFormFields,
    clearFormFields,
    setIsDefaultImageUsed,
    setEnableCaptureImage,
    setProfileImage,
    setIsEditing,
  };
};

export default usePropsToGetFormData;
