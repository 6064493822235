import { useEffect } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

function TestBackPress() {
  const navType = useNavigation();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("navType = ", navType);

    if (navType && navType.location && navType.location.pathname) {
      console.log("location = ", navType.location.pathname);
      if (navType.location.pathname === "/dashboard") {
        window.alert("hi");
        navigate(1);
      }
    }
  }, [navType]);
  return (
    <div style={{ color: "black" }}>
      <h1>My React App</h1>
      <p>Try to navigate away from this page after making changes.</p>
    </div>
  );
}
export default TestBackPress;
