// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";
import {
  FEMALE_LIST_COUNT,
  MALE_LIST_COUNT,
  MEMBER_DETAILS,
} from "../../Constants";
import { getCurrentDateTimeFormatted } from "../../util/viewUtils";

function setMaleFemaleListCount(savedGroomLists) {
  localStorage.setItem(
    MALE_LIST_COUNT,
    savedGroomLists.filter((l) => l.gender === "MALE").length
  );
  localStorage.setItem(
    FEMALE_LIST_COUNT,
    savedGroomLists.filter((l) => l.gender === "FEMALE").length
  );
}
function increaseMaleFemaleListCount(newList) {
  let counter = MALE_LIST_COUNT;
  if (newList.gender === "FEMALE") {
    counter = FEMALE_LIST_COUNT;
  }
  const currentCount = localStorage.getItem(counter);
  localStorage.setItem(counter, parseInt(currentCount) + 1);
}
function decreaeMaleFemaleListCount(oldList) {
  let counter = MALE_LIST_COUNT;
  if (oldList.gender === "FEMALE") {
    counter = FEMALE_LIST_COUNT;
  }
  const currentCount = localStorage.getItem(counter);
  localStorage.setItem(counter, parseInt(currentCount) - 1);
}
let oldSavedGroomList = [];
const stateBeforeRefresh = loadState();
if (stateBeforeRefresh.savedGroomLists) {
  oldSavedGroomList = stateBeforeRefresh.savedGroomLists.savedGroomLists;
}

console.log("in groomListsSlice, previous state=", stateBeforeRefresh);
const savedGroomListsSlice = createSlice({
  name: "savedGroomLists",
  initialState: {
    savedGroomLists: [...oldSavedGroomList],
    isGroomListsLoading: "",
  },
  reducers: {
    clearGroomLists(state, action) {
      state.savedGroomLists = [];
    },
    fetchSavedGroomListsSuccess(state, action) {
      const savedGroomLists = action.payload;
      console.log("inside fetchSavedGroomListsSuccess,action = ", action);
      state.savedGroomLists.push(...savedGroomLists);
      setMaleFemaleListCount(state.savedGroomLists);
    },
    addNewList(state, action) {
      const newList = action.payload;
      console.log("inside addNewList,action = ", action);
      //I was seeing an infinite loop, the groomList was getting increased with the same list over and over again
      //ideally it should be fixed there, but for now, just don't allow duplicate name list to be inserted
      const existingList = state.savedGroomLists.filter(
        (l) => l.name === newList.name && l.gender === newList.gender
      );
      if (existingList.length < 1) {
        state.savedGroomLists.push(newList);
        increaseMaleFemaleListCount(newList);
      } else {
        console.log(
          "==============================HACK WORKED=========================="
        );
      }
    },
    attachMemberDetailsToList(state, action) {
      console.log("inside attachMemberDetailsToList,action = ", action);
      const parentListId = action.payload.parentListId;
      const memberDetails = action.payload.memberDetails;
      const parentList = state.savedGroomLists.find(
        (l) => l.id == parentListId
      );
      parentList[MEMBER_DETAILS] = memberDetails;
      console.log(
        "Must be the same: ",
        parentList.membersCount,
        memberDetails.length
      );
    },
    deleteList(state, action) {
      const listIdToDelete = action.payload;
      console.log("inside deleteList, action = ", action);
      decreaeMaleFemaleListCount(
        state.savedGroomLists.filter((list) => listIdToDelete == list.id)
      );
      state.savedGroomLists = state.savedGroomLists.filter(
        (list) => list.id != listIdToDelete
      );
    },
    renameList(state, action) {
      const parentListId = action.payload.parentListId;
      const newListName = action.payload.newListName;
      console.log("inside renameList, action = ", action);
      const parentList = state.savedGroomLists.find(
        (l) => l.id == parentListId
      );
      parentList.name = newListName;
    },
    addMemberIds(state, action) {
      const { listId, memberIds } = action.payload;
      console.log("inside addMemberIds, action = ", action);
      state.savedGroomLists = state.savedGroomLists.map((list) => {
        if (list.id == listId) {
          return {
            ...list,
            memberIds: [...list.memberIds, ...memberIds],
            membersCount: list.membersCount + memberIds.length,
            updatedOn: getCurrentDateTimeFormatted(),
          };
        }
        return list;
      });
    },
    fetchStarted(state, action) {
      state.isGroomListsLoading = "1";
    },
    fetchEnded(state, action) {
      state.isGroomListsLoading = "";
    },
    removeMemberIds(state, action) {
      const { listId, memberIdsToRemove } = action.payload;
      console.log("inside removeMemberIds, action = ", action);
      state.savedGroomLists = state.savedGroomLists.map((list) => {
        if (list.id == listId) {
          return {
            ...list,
            memberIds: list.memberIds.filter(
              (id) => !memberIdsToRemove.includes(id)
            ),
            membersCount: list.membersCount - memberIdsToRemove.length,
            updatedOn: getCurrentDateTimeFormatted(),
          };
        }
        return list;
      });
    },
  },
});

export default savedGroomListsSlice.reducer;
export const {
  fetchSavedGroomListsSuccess,
  addNewList,
  attachMemberDetailsToList,
  deleteList,
  renameList,
  clearGroomLists,
  addMemberIds,
  removeMemberIds,
  fetchStarted,
  fetchEnded,
} = savedGroomListsSlice.actions;

//todo the local storage should be cleared when we logout, else for someone else logging it the redux will be restored.
