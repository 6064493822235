import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LanguageField from "../../components/appSettings/languageField/LanguageField";
import { BASE_URL, LANGUAGE_KEY } from "../../Constants";
import { doNavigate, getAuthToken } from "../../util/auth";
import { Loader } from "../UI/ErrorModal";
import styles from "./AppSettings.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useSelector } from "react-redux";
import { textFor } from "../../util/languages";
function AppSettings(props) {
  const auth = useSelector((state) => state.auth);
  console.log("in AppSettings props is ", props);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    } else {
      if ("status" in props.registrationDetails) {
        if (props.registrationDetails.status === 403) {
          console.log(
            "Logging out because in AppSettings, status is ",
            props.registrationDetails.status
          );
          doNavigate(navigate, "/logout");
        } else {
          console.log("In AppSettings, status is not 403, props = ", props);
        }
      }
    }
  }, [props, navigate]);

  const initialUsersDetails = {
    // usersFontSize: props.registrationDetails.fontSize,
    // usersThemeId: props.registrationDetails.themeId,
    usersLanguageName: props.registrationDetails.language || "ENGLISH",
  };
  const [userDetails, setUserDetails] = useState(initialUsersDetails);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const updateLanguage = (value) => {
    setUserDetails((userDetails) => {
      return { ...userDetails, usersLanguageName: value };
    });
  };
  const handleSaveClick = async () => {
    setIsEditing(false);
    setSubmitting(true);
    try {
      let url = BASE_URL + "/normalUser/updateRegistrationDetails";
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify({
          language: userDetails.usersLanguageName,
        }),
      });

      console.log("response is = ", response);
      if (!response.ok) {
        doNavigate(navigate, "/logout");
      } else {
        localStorage.setItem(LANGUAGE_KEY, userDetails.usersLanguageName);
        window.alert(textFor("pleaseLoginAgainToMakeSettingsEffective"));
        doNavigate(navigate, "/logout");
      }
    } catch (error) {
      console.error("Error during POST request:", error);
      doNavigate(navigate, "/logout");
    } finally {
      setSubmitting(false);
    }
  };
  const handleCancelClick = () => {
    setUserDetails((userDetails) => initialUsersDetails);
    setIsEditing(false);
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  return (
    <>
      {isSubmitting && <Loader />}
      <div className={styles.userDetailsContainer}>
        <div className={commonStyles.actions}>
          {isEditing ? (
            <>
              <button
                id="settingsSaveButton"
                className={commonStyles.greenButton}
                onClick={handleSaveClick}
              >
                Save
              </button>
              <button
                id="settingsCancelButton"
                className={commonStyles.magentaButton}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              id="settingsEditButton"
              className={commonStyles.brownButton}
              onClick={handleEditClick}
            >
              Edit
            </button>
          )}
        </div>
        <LanguageField
          usersLanguageName={userDetails.usersLanguageName}
          isEditing={isEditing}
          updateLanguage={updateLanguage}
        />
      </div>
      <div style={{ color: "black", padding: "0 1em 0 1em" }}>
        {textFor("comingSoonMoreSettings")}
      </div>
    </>
  );
}

export default AppSettings;
