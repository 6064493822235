import Dropdown from "../../components/UI/dropdown/Dropdown";
import styles from "./SearchFilters.module.scss";
import educationOptionsJson from "../../jsonConstants/educationTypes.json";
import heightFeetJson from "../../jsonConstants/heightFeet.json";
import heightInchJson from "../../jsonConstants/heightInch.json";
import distanceRangeTypeJson from "../../jsonConstants/distanceRangeTypes.json";
import maritalStatusOptionsJson from "../../jsonConstants/maritalStatusTypes.json";
import yesNoOptionsJson from "../../jsonConstants/yesNo.json";
import genderTypeOptionsJson from "../../jsonConstants/genderTypes.json";
import yearlyIncomeOptionsJson from "../../jsonConstants/yearlyIncome.json";
import MultiColumnMultiSelectView from "./MultiColumnMultiSelectView";
import gotraTypesJson from "../../jsonConstants/gotraTypes.json";
import kundliDoshaTypesJson from "../../jsonConstants/kundliDosha.json";
import {
  DISTANCE_RANGE,
  FROM_HEIGHT_FEET,
  FROM_HEIGHT_INCHES,
  MARITAL_STATUS,
  MAX_AGE,
  MAX_WEIGHT,
  MIN_AGE,
  MIN_EDU,
  MIN_WEIGHT,
  PURE_VEG,
  SPECIAL_ABILITY,
  TO_HEIGHT_FEET,
  TO_HEIGHT_INCHES,
  MINIMUM_YEARLY_INCOME,
  NAME,
  PHONE,
  GOTRA,
  KUNDLI_DOSHA,
  GENDER,
} from "../../Constants";
const { gotraTypeOptions } = gotraTypesJson;
const { kundliDoshaTypeOptions } = kundliDoshaTypesJson;
const { heightFeetOptions } = heightFeetJson;
const { heightInchOptions } = heightInchJson;
const { educationOptions } = educationOptionsJson;
const { maritalStatusOptions } = maritalStatusOptionsJson;
const { yesNoOptions } = yesNoOptionsJson;
const { genderTypeOptions } = genderTypeOptionsJson;
const { yearlyIncomeOptions } = yearlyIncomeOptionsJson;
const { distanceRangeTypeOptions } = distanceRangeTypeJson;

function getBoxWithTitleAndCloseButton(title, onClose, getChildrenViews) {
  return (
    <div className={styles.boxWithBorder}>
      <div className={styles.titleAndCloseButton}>
        <div className={styles.topLeftBoxTitle}>{title}</div>
        <div className={styles.closeButtonWrapper}>
          <div onClick={onClose} className={styles.topRightCloseBtn}>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      </div>
      <div className={styles.boxContent}>{getChildrenViews()}</div>
    </div>
  );
}
export function getDistanceFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  function distanceRangeChildrenViews() {
    return (
      <div>
        <label>Distance from your city:</label>
        <div className={styles.filterDropdown}>
          <Dropdown
            name="withinDistance"
            message="Distance Range"
            label=""
            value={formData[DISTANCE_RANGE]}
            onChange={(e) => setFilterValue(DISTANCE_RANGE, e.target.value)}
            options={distanceRangeTypeOptions}
            disabled={false}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(
        title,
        onClose,
        distanceRangeChildrenViews
      )}
    </div>
  );
}
export function getAgeFilterView(title, onClose, formData, setFilterValue) {
  let ageOptions = [];
  for (let i = 18; i <= 90; i++) {
    ageOptions.push({ id: "" + i, name: "" + i });
  }
  function ageChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="minAge"
          message="Age"
          label=""
          value={formData[MIN_AGE]}
          onChange={(e) => setFilterValue(MIN_AGE, e.target.value)}
          options={ageOptions}
          disabled={false}
        />
        <div>To</div>
        <Dropdown
          name="maxAge"
          message="Age"
          label=""
          value={formData[MAX_AGE]}
          onChange={(e) => setFilterValue(MAX_AGE, e.target.value)}
          options={ageOptions}
          disabled={false}
        />
        <div>Years</div>
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, ageChildrenViews)}
    </div>
  );
}
export function getEducationFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  function getEduFilterChildrenView() {
    return (
      <div className={styles.filterDropdown}>
        <div className={styles.fieldLabelMultiline}>
          <div>Minimum</div>
          <div>Education</div>
        </div>
        <Dropdown
          name="MinEducation"
          message="Choose Education"
          label=""
          value={formData[MIN_EDU]}
          onChange={(e) => setFilterValue(MIN_EDU, e.target.value)}
          options={educationOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, getEduFilterChildrenView)}
    </div>
  );
}
export function getHeightFilterView(title, onClose, formData, setFilterValue) {
  function getHeightChildrenViews() {
    return (
      <>
        <div className={styles.filterDropdown}>
          <Dropdown
            name="minHeightFeetType"
            message=""
            label=""
            value={formData[FROM_HEIGHT_FEET]}
            onChange={(e) => setFilterValue(FROM_HEIGHT_FEET, e.target.value)}
            options={heightFeetOptions}
            disabled={false}
          />
          <div>Feet</div>
          <Dropdown
            name="minHeightInchType"
            message="Inch"
            label=""
            value={formData[FROM_HEIGHT_INCHES]}
            onChange={(e) => setFilterValue(FROM_HEIGHT_INCHES, e.target.value)}
            options={heightInchOptions}
            disabled={false}
          />
          <div>Inches</div>
        </div>
        <div className={styles.filterDropdown}>
          <Dropdown
            name="maxHeightFeetType"
            message="Feet"
            label=""
            value={formData[TO_HEIGHT_FEET]}
            onChange={(e) => setFilterValue(TO_HEIGHT_FEET, e.target.value)}
            options={heightFeetOptions}
            disabled={false}
          />
          <div>Feet</div>
          <Dropdown
            name="maxHeightInchType"
            message="Inch"
            label=""
            value={formData[TO_HEIGHT_INCHES]}
            onChange={(e) => setFilterValue(TO_HEIGHT_INCHES, e.target.value)}
            options={heightInchOptions}
            disabled={false}
          />
          <div>Inches</div>
        </div>
      </>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, getHeightChildrenViews)}
    </div>
  );
}
export function getWeightFilterView(title, onClose, formData, setFilterValue) {
  function weightChildrenViews() {
    let weightOptions = [];
    for (let i = 20; i <= 130; i += 5) {
      weightOptions.push({ id: "" + i, name: "" + i });
    }
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="minWeight"
          message="Min Weight"
          label=""
          value={formData[MIN_WEIGHT]}
          onChange={(e) => setFilterValue(MIN_WEIGHT, e.target.value)}
          options={weightOptions}
          disabled={false}
        />
        <div>To</div>
        <Dropdown
          name="maxWeight"
          message="Max Weight"
          label=""
          value={formData[MAX_WEIGHT]}
          onChange={(e) => setFilterValue(MAX_WEIGHT, e.target.value)}
          options={weightOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, weightChildrenViews)}
    </div>
  );
}
export function getGenderFilterView(title, onClose, formData, setFilterValue) {
  function genderChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="gender"
          message={title}
          label=""
          value={formData[GENDER]}
          onChange={(e) => setFilterValue(GENDER, e.target.value)}
          options={genderTypeOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, genderChildrenViews)}
    </div>
  );
}
export function getPureVegFilterView(title, onClose, formData, setFilterValue) {
  function pureVegChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="pureveg"
          message="Pure Veg?"
          label=""
          value={formData[PURE_VEG]}
          onChange={(e) => setFilterValue(PURE_VEG, e.target.value)}
          options={yesNoOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, pureVegChildrenViews)}
    </div>
  );
}
export function getSpeciallyAbledFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  function physicalAbilityChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="diffAbled"
          message="Specially abled"
          label=""
          value={formData[SPECIAL_ABILITY]}
          onChange={(e) => setFilterValue(SPECIAL_ABILITY, e.target.value)}
          options={yesNoOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(
        title,
        onClose,
        physicalAbilityChildrenViews
      )}
    </div>
  );
}
export function getNameFilterView(title, onClose, formData, setFilterValue) {
  function nameChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <input
          id="nameFilterInputBox"
          type="text"
          placeholder="At least 2 letters of name"
          value={formData[NAME]}
          onChange={(e) => setFilterValue(NAME, e.target.value)}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, nameChildrenViews)}
    </div>
  );
}

export function getPhoneFilterView(title, onClose, formData, setFilterValue) {
  function phoneChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <input
          type="number"
          placeholder="At least 5 digits of phone"
          value={formData[PHONE]}
          onChange={(e) => setFilterValue(PHONE, e.target.value)}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, phoneChildrenViews)}
    </div>
  );
}
export function getMaritalStatusFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  const maritalStatusOptionsWithoutMarried = maritalStatusOptions.filter(
    (m) => m.id !== "MARRIED"
  );
  return (
    <MultiColumnMultiSelectView
      title={title}
      onClose={onClose}
      options={maritalStatusOptionsWithoutMarried}
      setFilterValue={setFilterValue}
      formData={formData}
      filterName={MARITAL_STATUS}
    />
  );
}
export function getYearlyIncomeFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  function yearlyIncomeChildrenViews() {
    return (
      <div className={styles.filterDropdown}>
        <Dropdown
          name="yearlyIncome"
          message="Select"
          label=""
          value={formData[MINIMUM_YEARLY_INCOME]}
          onChange={(e) =>
            setFilterValue(MINIMUM_YEARLY_INCOME, e.target.value)
          }
          options={yearlyIncomeOptions}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className={styles.filterFieldWrapper}>
      {getBoxWithTitleAndCloseButton(title, onClose, yearlyIncomeChildrenViews)}
    </div>
  );
}
export function getGotraFilterView(title, onClose, formData, setFilterValue) {
  return (
    <MultiColumnMultiSelectView
      title={title}
      onClose={onClose}
      options={gotraTypeOptions}
      setFilterValue={setFilterValue}
      formData={formData}
      filterName={GOTRA}
    />
  );
}
export function getKundliDoshaFilterView(
  title,
  onClose,
  formData,
  setFilterValue
) {
  return (
    <MultiColumnMultiSelectView
      title={title}
      onClose={onClose}
      options={kundliDoshaTypeOptions}
      setFilterValue={setFilterValue}
      formData={formData}
      filterName={KUNDLI_DOSHA}
    />
  );
}
// export function getGotraFilterViewWithCloseableTags(title, onClose) {
//   function handleChange(e) {}
//   function getCloseableTag(text) {
//     return (
//       <div className={styles.tagWithCloseButton}>
//         <div>{text}</div>
//         <div onClick={handleChange} className={styles.tagCloseButton}>
//           <i className="fa-solid fa-xmark"></i>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.filterFieldWrapper}>
//       {getBoxWithTitleAndCloseButton(title, onClose)}
//       <div className={styles.tagsContainer}>
//         <div>32 gotras included</div>
//         <button>edit</button>
//       </div>
//     </div>
//   );
// }
