import { LOGIN_STATUS } from "../Constants";

export function loadState() {
  console.log("In loadState of localStorageReader......");
  if (!localStorage.getItem(LOGIN_STATUS)) {
    return {};
  }
  try {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith("reduxState_")
    );

    const state = {};
    keys.forEach((key) => {
      const serializedState = localStorage.getItem(key);
      const sliceName = key.replace("reduxState_", "");
      if (serializedState !== null) {
        state[sliceName] = JSON.parse(serializedState);
      }
    });
    return state;
  } catch (err) {
    return undefined;
  }
}
