import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";

let oldSavedFamilyMembers = [];
const stateBeforeRefresh = loadState();
if (stateBeforeRefresh.familyMembers) {
  oldSavedFamilyMembers = stateBeforeRefresh.familyMembers.members;
}
const familyMembersSlice = createSlice({
  name: "familyMembers",
  initialState: { members: [...oldSavedFamilyMembers] },
  reducers: {
    //we will call this reducer only when a member is deleted
    fetchMembersSuccess(state, action) {
      const members = action.payload;
      console.log("inside fetchMembersSuccess,action = ", action);
      state.members = members;
    },
    clearFamilyMembers(state, action) {
      state.members = [];
      stateBeforeRefresh.familyMembers = null;
      oldSavedFamilyMembers = [];
    },
    updateFamilyMember(state, action) {
      console.log(action.payload);
      const updatedMember = action.payload;
      state.members = state.members.map((m) => {
        if (m.id == updatedMember.id) {
          return {
            ...m,
            ...updatedMember,
          };
        }
        return m;
      });
    },
  },
});

export default familyMembersSlice.reducer;
export const { fetchMembersSuccess, clearFamilyMembers, updateFamilyMember } =
  familyMembersSlice.actions;
