import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";

let oldSearchedGrooms = [];
const stateBeforeRefresh = loadState();
if (stateBeforeRefresh.searchedGrooms) {
  oldSearchedGrooms = stateBeforeRefresh.searchedGrooms.searchedResults;
}

const searchedGroomSlice = createSlice({
  name: "searchedGrooms",
  initialState: { searchedResults: oldSearchedGrooms },

  reducers: {
    fetchedResults(state, action) {
      const {
        elements,
        pageNumber,
        pageSize,
        reqId,
        sortBy,
        sortOrder,
        totalElements,
        totalPages,
      } = action.payload;
      console.log("inside fetchedResults, action = ", action.payload);
      if (Object.keys(state.searchedResults).includes("elements")) {
        state.searchedResults.elements.push(...elements); //push mutates existing array
        state.searchedResults.pageNumber = pageNumber;
        state.searchedResults.pageSize = pageSize;
        state.searchedResults.reqId = reqId;
        state.searchedResults.sortBy = sortBy;
        state.searchedResults.sortOrder = sortOrder;
        state.searchedResults.totalElements = totalElements;
        state.searchedResults.totalPages = totalPages;
      } else {
        state.searchedResults = action.payload;
      }
    },
    clearSearchedGroomResults(state, action = {}) {
      state.searchedResults = {};
    },
  },
});

export default searchedGroomSlice.reducer;
export const { fetchedResults, clearSearchedGroomResults } =
  searchedGroomSlice.actions;
