import { useNavigate, useRouteError } from "react-router-dom";
import commonStyles from "../commonStyles.module.scss";
import { textFor } from "../util/languages";
function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.log("error: ", error);
  let title = "An error occurred!";
  let message = "Something went wrong!";

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = textFor("sorryForThisError");
    message = textFor("touchBelowButtonToGoBack");
  }

  return (
    <>
      <p>{title}</p>
      <p>{message}</p>
      <button
        id="gotoDashboardButton"
        className={commonStyles.blueButton}
        style={{ width: "7em", marginLeft: "5px" }}
        type="button"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        {textFor("dashboard")}
      </button>
    </>
  );
}

export default ErrorPage;
