import { Suspense } from "react";
import {
  useRouteLoaderData,
  json,
  defer,
  Await,
  useLocation,
} from "react-router-dom";
import {
  BASE_URL,
  SEARCH_GROOM_CRITERIA,
  OPTIONAL_FILTERS,
  SEARCH_GENDER,
} from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import SavedGroomLists from "./SavedGroomLists";
import { fetchSavedGroomListsSuccess } from "../../store/reducers/savedGroomListsSlice";
import { Loader } from "../../components/UI/ErrorModal";
import { authActions } from "../../store/reducers/authSlice";
import { saveActiveRoute } from "../../util/viewUtils";

function GroomListsLoader(props) {
  const { routeLoaderData } = useRouteLoaderData("searchGroom");
  const activeRoute = useLocation();
  saveActiveRoute(activeRoute);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => {
            console.log("routeLoaderData=", routeLoaderData);
            return <SavedGroomLists data={routeLoaderData} />;
          }}
        </Await>
      </Suspense>
    </>
  );
}
export default GroomListsLoader;
export async function loadSavedGroomLists(dispatch, savedGroomLists) {
  console.log("groomList loader started....");
  return defer({
    routeLoaderData: fetchSavedGroomLists(dispatch),
  });
}

export async function fetchSavedGroomLists(dispatch) {
  localStorage.removeItem(SEARCH_GROOM_CRITERIA);
  localStorage.removeItem(OPTIONAL_FILTERS);
  const gender = localStorage.getItem(SEARCH_GENDER);
  return await getGroomListByGender(gender, dispatch);
}
async function getGroomListByGender(gender, dispatch) {
  const reduxGroomLists = JSON.parse(
    localStorage.getItem("reduxState_savedGroomLists")
  );
  const currentGenderList = reduxGroomLists.savedGroomLists.filter(
    (list) => list.gender === gender
  );
  let genderBasedReduxListsLength = currentGenderList.length;

  console.log(
    "inside getGroomListByGender, genderBasedReduxListsLength=",
    genderBasedReduxListsLength
  );
  if (
    genderBasedReduxListsLength < 1 &&
    localStorage.getItem("activeRoute").includes("dashboard")
  ) {
    let retVal = await fetchGroomListsAndUpdateRedux(
      gender,
      dispatch,
      genderBasedReduxListsLength
    );
    console.log("returning from getGroomListByGender after Fetching: ", retVal);
    return retVal;
  }
  console.log("returning null from getGroomListByGender");

  return null;
}
async function fetchGroomListsAndUpdateRedux(
  gender,
  dispatch,
  genderBasedReduxListsLength
) {
  console.log("inside fetchGroomListsAndUpdateRedux");
  const token = getAuthToken();
  const url = BASE_URL + "/grooms/allLists?gender=" + gender;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response from fetch during fetchSavedGroomLists = ", response);

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json({
          message: "Could not fetch details for fetchSavedGroomLists.",
          status: 500,
        });
      }
    } else {
      const resData = await response.json();
      console.log("got response ", resData);
      if (resData.length !== genderBasedReduxListsLength) {
        dispatch(fetchSavedGroomListsSuccess(resData));
      }
      console.log("returning from fetchGroomListsAndUpdateRedux");
      return resData;
      // return getGroomLists();
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during fetchSearchedPeople:", error);
    // const status = error.status;
    // console.error("status = ", status);
    // return error;
    return getGroomLists();
  } //catch
}
function getGroomLists() {
  return [];
  // return dummyGetGroomLists();
}
