import { useNavigate, json } from "react-router-dom";
import AlertDialog from "../alertDialog/AlertDialog";
import { useDispatch } from "react-redux";
import { BASE_URL, SEARCH_GENDER } from "../../../Constants";
import { getAuthToken } from "../../../util/auth";
import {
  deleteList,
  renameList,
} from "../../../store/reducers/savedGroomListsSlice";
import styles from "./EditListDialog.module.scss";
import commonStyles from "../../../commonStyles.module.scss";
import { useRef, useState } from "react";
import { textFor } from "../../../util/languages";
import { Loader } from "../ErrorModal";
import { patchWithoutBody } from "../../../util/apiUtil";
import { authActions } from "../../../store/reducers/authSlice";
function EditListDialog({ onClose, parentListId, parentListName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shouldShowLoader, setShouldShowLoader] = useState("");
  const newListNameRef = useRef(null);
  async function deleteListClicked(e) {
    const confirm = window.confirm(textFor("areYouSureToDeleteList"));
    if (confirm) {
      setShouldShowLoader("1");
      localStorage.setItem("lastPath", "listMembers");
      try {
        const token = getAuthToken();
        let url = BASE_URL + "/grooms/list?listId=" + parentListId;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "DELETE",
          headers: headers,
        });
        console.log("response from fetch during delete list = ", response);

        if (!response.ok) {
          if (response.status === 403) {
            return response;
          } else {
            throw json({
              message: "Could not delete list id " + parentListId,
              status: 500,
            });
          }
        } else {
          window.alert(textFor("listSuccessfullyDeleted"));
          dispatch(deleteList(parentListId));
        }
      } catch (error) {
        console.log("Error in deleting list: ", parentListName);
      } finally {
        setShouldShowLoader("");
      }
    }
  }
  function isListThereWithSameName(newListName) {
    const activeGender = localStorage.getItem(SEARCH_GENDER);
    const reduxSavedGroomLists = JSON.parse(
      localStorage.getItem("reduxState_savedGroomLists")
    );
    const objWithId = reduxSavedGroomLists.savedGroomLists.some(
      (obj) => obj.gender === activeGender && obj.name === newListName
    );
    if (objWithId) {
      return true;
    }
    return false;
  }
  async function renameButtonClicked(e) {
    const newListName = newListNameRef.current.value;
    if (newListName.length < 2) {
      window.alert(textFor("atLeast2LettersRequiredForListName"));
      return;
    }
    if (
      newListName.trim() == parentListName.trim() ||
      isListThereWithSameName(newListName.trim())
    ) {
      window.alert(textFor("newAndOldListNameMustBeDifferent"));
      return;
    }
    const confirm = window.confirm(textFor("areYouSureToRenameList"));

    if (confirm) {
      setShouldShowLoader("1");
      try {
        let url =
          BASE_URL +
          "/grooms/editList?listId=" +
          parentListId +
          "&newListName=" +
          newListName;
        const response = await patchWithoutBody(url, dispatch, authActions);
        if (!response.ok) {
          if (response.status === 400) {
            const errorResponse = await response.json();
            if (errorResponse.errorMessage.include()) {
              window.alert(textFor("topUpWalletFromProfile"));
            }
          }
        } else {
          window.alert(textFor("listRenamedSuccessfully"));
          parentListName = newListName;
          dispatch(renameList({ parentListId, newListName }));
        }
      } catch (error) {
        console.log("Error in deleting list: ", parentListName);
      } finally {
        setShouldShowLoader("");
      }
      return;
    }
  }
  function getDeleteListButtonView() {
    return (
      <button
        style={{ margin: "15px 0" }}
        className={commonStyles.brownButton}
        type="button"
        onClick={deleteListClicked}
      >
        {textFor("delete")}
      </button>
    );
  }
  function getListNameInTextBoxView() {
    return (
      <div className={styles.createNewListPanel}>
        <input
          ref={newListNameRef}
          type="text"
          placeholder={parentListName}
        ></input>
        <button
          className={commonStyles.magentaButton}
          onClick={renameButtonClicked}
          type="button"
        >
          {textFor("rename")}
        </button>
      </div>
    );
  }
  function getEditListView() {
    return (
      <>
        {getListNameInTextBoxView()}
        {getDeleteListButtonView()}
      </>
    );
  }
  return (
    <>
      {shouldShowLoader && <Loader />}
      <AlertDialog
        onOkClick={onClose}
        onBackdropClicked={onClose}
        title={textFor("editList")}
        getContent={getEditListView}
        okButtonText={textFor("cancel")}
      />
    </>
  );
}

export default EditListDialog;
