import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/reducers/authSlice";
import { clearGroomLists } from "../store/reducers/savedGroomListsSlice";
import { clearProfile } from "../store/reducers/userProfileSlice";
import { clearFamilyMembers } from "../store/reducers/familyMembersSlice";
import { clearFriends } from "../store/reducers/friendsSlice";
import { clearLocalStorage, doNavigate } from "../util/auth";
import { LOGIN_STATUS } from "../Constants";

function Logout() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.removeItem(LOGIN_STATUS);
  useEffect(() => {
    localStorage.removeItem(LOGIN_STATUS);
    dispatch(authActions.logoutRequest());
    dispatch(clearGroomLists());
    dispatch(clearProfile());
    dispatch(clearFamilyMembers());
    dispatch(clearFriends());
    clearLocalStorage();
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [isAuthenticated, navigate]);
  return <>Logging out....</>;
}

export default Logout;

//todo during logout the redux should be cleared
