import { useEffect, useRef, useState } from "react";
import { Loader } from "../../components/UI/ErrorModal";
import { useSelector } from "react-redux";
import PersonDetailsDialog from "../../components/UI/PersonDetailsDialog/PersonDetailsDialog";
import styles from "../searchGroom/SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import { json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import EditListDialog from "../../components/UI/EditListDialog/EditListDialog";
import { BASE_URL } from "../../Constants";
import { doLogout, doNavigate, getAuthToken } from "../../util/auth";
import { removeMemberIds } from "../../store/reducers/savedGroomListsSlice";
import { authActions } from "../../store/reducers/authSlice";
import { textFor } from "../../util/languages";

function ListMembersGridView({ elements, parentListId, parentListName }) {
  console.log("parentListId = ", parentListId);
  console.log("elements in ListMembersGridView=", elements);
  const viewRef = useRef(null);

  const dispatch = useDispatch();

  const reduxSavedGroomLists = JSON.parse(
    localStorage.getItem("reduxState_savedGroomLists")
  );
  const savedGroomLists = reduxSavedGroomLists.savedGroomLists;
  const [isSendingReqest, setIsSendingRequest] = useState("");
  const [data, setData] = useState({
    elements,
  });
  const [personToViewDetail, setPersonToViewDetail] = useState(null);
  const [shouldShowMemberDetailDialog, setDialogShow] = useState("");
  const [shouldShowEditListDialog, setEditListDialogShow] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shouldShowParentListDetails, setParentListDetails] = useState("1");
  //---------------------------------------------------------------
  useEffect(() => {
    const parentList = savedGroomLists.find((list) => list.id == parentListId);
    if (parentList) {
      setData({ elements: parentList.memberDetails });
      // Restore scroll position after the state update
      if (viewRef.current) {
        viewRef.current.scrollTop = scrollPosition;
        console.log("setting scroll position to ", scrollPosition);
      }
    }
  }, [shouldShowMemberDetailDialog]); // Trigger effect when data changes
  //---------------------------------------------------------------
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated]);
  //---------------------------------------------------------------
  if (!parentListId) {
    //if list is deleted then navigate back to searchGroom
    return (
      <div className={commonStyles.centeredMessageContainer}>
        <div id="listIsDeletedLine1" className={commonStyles.centeredMessage}>
          {textFor("thisListIsDeleted")}
        </div>
        <div id="listIsDeletedLine2" className={commonStyles.centeredMessage}>
          {textFor("pressBackAndGoBack")}
        </div>
      </div>
    );
  }
  function saveScrollPosition() {
    setScrollPosition(viewRef.current.scrollTop);
  }
  function hideEditListDialog() {
    setEditListDialogShow("");
  }
  function hidePersonDetailDialog() {
    setPersonToViewDetail(null);
    setDialogShow("");
  }
  function ifNeedsShowMemberDetailDialog() {
    if (shouldShowMemberDetailDialog) {
      return (
        <PersonDetailsDialog
          onClose={hidePersonDetailDialog}
          personDetails={personToViewDetail}
          getViewForTopRightArea={showParentListDetailsIfAvailable}
        />
      );
    } else {
      return "";
    }
  }
  function ifNeedsShowEditListDialog() {
    if (shouldShowEditListDialog) {
      return (
        <EditListDialog
          onClose={hideEditListDialog}
          parentListId={parentListId}
          parentListName={parentListName}
        />
      );
    } else {
      return "";
    }
  }
  function viewPerson(member) {
    const m = { ...member };
    console.log("Viewing person = ", m);
    m["parentListId"] = parentListId;
    m["parentListName"] = parentListName;
    setPersonToViewDetail(m);
    setParentListDetails("1"); //show it for next personToViewDetails
    saveScrollPosition();
    setDialogShow("1");
  }
  function getFullNameField(m) {
    return (
      <div className={commonStyles.fullNameWrapper}>
        <div className={commonStyles.fullName}>{m.fullName}</div>
      </div>
    );
  }
  function getImageAndTextForMemberView(m) {
    let profileImageUrl = m.profileImageUrl;
    if (!profileImageUrl) {
      profileImageUrl = userPlaceholderImageUrl;
    }
    return (
      <>
        <div
          onClick={(e) => {
            viewPerson(m);
            //don't call a method on onClick, provide a function which will do the calling or just provide name of the function
          }}
          className={styles.imgDiv}
        >
          <img src={profileImageUrl} alt="profilePic" />
        </div>
        {getFullNameField(m)}
        <p>{m.genderType}</p>
      </>
    );
  }
  function getMemberView(m) {
    return (
      <div key={m.id} className={styles.grid__item}>
        {getImageAndTextForMemberView(m)}
      </div>
    );
  }
  function getMembersInGridView() {
    if (data.elements.length < 1) {
      return (
        <div className={styles.centeredMessageContainer}>
          <div id="noPersonFoundInListMsg" className={styles.centeredMessage}>
            {textFor("noPersonFoundInList")}
          </div>
        </div>
      );
    } else {
      return (
        <div ref={viewRef} className={styles.grid}>
          {data.elements.map((item, index) => (
            <div key={item.id} className={styles.grid__item}>
              {getMemberView(item)}
            </div>
          ))}
        </div>
      );
    }
  }
  function editListClicked(e) {
    setEditListDialogShow("1");
  }

  function getLowerPanelButtonsAndLoadedStatus() {
    return (
      <div className={styles.lowerButtonPanelWrapper}>
        <div className={styles.lowerButtonPanel}>
          <button
            style={{ marginLeft: "3px" }}
            className={commonStyles.darkRedButton}
            type="button"
            onClick={editListClicked}
          >
            {textFor("editList")}
          </button>
          <div className={commonStyles.currentlyShowingCount}>
            {data.elements.length}/{data.elements.length}
          </div>
        </div>
      </div>
    );
  }
  function getViewToShowPersonsGrid() {
    return (
      <div>
        <div className={commonStyles.topPanel} style={{ color: "black" }}>
          Members in {parentListName}
        </div>
        <div className={commonStyles.scrollableContainer} id="persons-view">
          {getMembersInGridView()}
          {getLowerPanelButtonsAndLoadedStatus()}
        </div>
      </div>
    );
  }
  async function deleteFromList(e) {
    if (window.confirm(textFor("areYouSureToDeleteMemberFromList"))) {
      try {
        setIsSendingRequest("1");
        const token = getAuthToken();
        let url =
          BASE_URL +
          "/grooms/deleteListMembers?listId=" +
          parentListId +
          "&groomListMemberIdList=" +
          personToViewDetail.id;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "DELETE",
          headers: headers,
        });
        console.log("delete member from list response = ", response);

        if (!response.ok) {
          if (response.status === 403) {
            return doLogout(dispatch, authActions);
          } else {
            throw json({
              message: "Could not delete member from list.",
              status: 500,
            });
          }
        } else {
          console.log("member from list response ", response);
          setParentListDetails("");
          dispatch(
            removeMemberIds({
              listId: parentListId,
              memberIdsToRemove: [personToViewDetail.id],
            })
          );
          window.alert(textFor("personIsDeletedFromList"));
          setDialogShow("");
        }
      } catch (error) {
        window.alert("Error: " + error);
        doLogout(dispatch, authActions);
      } finally {
        setIsSendingRequest("");
      }
    }
  }
  function showParentListDetailsIfAvailable() {
    if (shouldShowParentListDetails) {
      return (
        <div className={commonStyles.nearImageBtnWrapper}>
          {/* <div className={styles.listInfoSection}>
            <i
              className="fa-solid fa-bookmark"
              style={{
                color: getRandomColorFromId(parentListId),
              }}
            ></i>
            <div
              style={{
                color: getRandomColorFromId(parentListId),
              }}
            >
              In: {personToViewDetail.parentListName}
            </div>
          </div> */}
          <button
            id="deletePersonButton"
            className={commonStyles.brownButton}
            onClick={deleteFromList}
            type="button"
          >
            {textFor("delete")}
          </button>
          {/* <i className="fa-solid fa-xmark"></i> */}
        </div>
      );
    } else {
      return "";
    }
  }
  return (
    <>
      {isSendingReqest && <Loader />}
      {ifNeedsShowMemberDetailDialog()}
      {ifNeedsShowEditListDialog()}
      {getViewToShowPersonsGrid()}
    </>
  );
}

export default ListMembersGridView;
